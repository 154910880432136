


export const statusOptionsEjecutante = [
  { status: 1, name: 'pendiente' },
  { status: 2, name: 'corrección' },
  { status: 3, name: 'proceso' },
  { status: 7, name: 'realizado' },
  { status: 8, name: 'cancelado' },
]
