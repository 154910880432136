

<template>

  <div>

    <!-- loading state -->
    <template v-if="isLoading">
      <div class="text-center">
        <b-spinner small variant="primary" label="Loading"></b-spinner>
      </div>
    </template>

    <!-- error section-->
    <template v-else-if="hasErrors">
      hubo errores
    </template>

    <!-- content section -->
    <template v-else>



      <!-- empty state-->
      <template v-if="isEmptyActivity">
      </template>
      <!-- data section -->
      <template v-else>

        <b-card no-body>
          <b-card-body>

            <app-timeline>

              <app-timeline-item
                v-for="(activity, index) in historialActividad"
                :key="activity.fstId"
                :variant="getFormStatusVariant(activity)"
              >

                <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                  <div>
                    <h6 class="mb-0">{{ activity.usrApellidos }}, {{ activity.usrNombres }}</h6>
                    <small class="timeline-item-time text-nowrap">{{ getResolveFormatoFechaHora(activity.created_at) }}</small>
                  </div>
                  <small class="timeline-item-time text-nowrap">
                    <template v-if="index === 0">
                      <b-badge pill :variant="getFormStatusVariant(activity)">
                        <span class="text-capitalize">{{ getFormStatusName(activity) }}</span>
                      </b-badge>
                    </template>
                    <template v-else>
                      <b-badge pill :variant="`light-${getFormStatusVariant(activity)}`">
                        <span class="text-capitalize">{{ getFormStatusName(activity) }}</span>
                      </b-badge>
                    </template>
                  </small>
                </div>

                <p class="mt-1">{{ activity.fstDetalle }}</p>

                <!-- Responder - opcion disponible solo en el ultimo estado -->
                <template v-if="index === 0 && isInProgress(activity.fstStatus)">
                  <b-button
                    variant="primary"
                    size="sm"
                    :disabled="isResolvingRespuesta"
                    @click="handleResponse(activity)"
                  >Responder</b-button>
                </template>

              </app-timeline-item>

            </app-timeline>

          </b-card-body>

        </b-card>


      </template>

    </template>


    <!-- modal para responder -->
    <b-modal
      v-model="showModalRespuesta"
      title="Responder"
      no-close-on-backdrop
      centered
      @hidden="resetModal"
    >

      <b-form-group
        label="Estado"
        label-for="status-selected"
      >
        <v-select
          id="status-selected"
          v-model="statusResponse.selectedStatus"
          label="name"
          :options="statusOptionsEjecutante"
        />
      </b-form-group>

      <b-form-group
        label="Comentario"
        label-for="status-detalle"
      >
        <b-form-textarea
          id="status-detalle"
          v-model="statusResponse.statusDetalle"
          rows="3"
        />
      </b-form-group>

      <template #modal-footer>
        <div class="w-100 text-right">
          <b-button variant="secondary" @click="handleCancelModal" >
            Cancelar
          </b-button>
          <b-button
            variant="primary"
            class="ml-1"
            @click="handleSendResponse"
            :disabled="isBusy || hasSomeNullEmptyItem"
          >
            <b-spinner small v-if="isBusy"/>
            Enviar
          </b-button>
        </div>
      </template>

    </b-modal>

  </div>

</template>

<script>

import { BButton, BCard, BCardBody, BSpinner, BModal, BFormGroup, BFormTextarea, BBadge } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions } from 'vuex'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import { resolveFormatoFechaHora, resolveFormStatusVariant, resolveTaskStatusName } from '@/modules/common/utils'
import { statusOptionsEjecutante } from '@/modules/common/data'
import { getUserData } from '@/modules/auth/utils'
import { COMPLETED_STATUS, CANCELED_STATUS } from '@/modules/common/constants'

export default {

  name: 'TareaActivity',

  components: {
    AppTimeline,
    AppTimelineItem,
    BCard,
    BCardBody,
    BButton,
    BSpinner,
    BModal,
    BFormGroup,
    BFormTextarea,
    BBadge,
    vSelect,
  },

  props: {
    frmId: {
      type: Number,
      required: true,
      default: 0
    }
  },

  data(){
    return {

      errors: [],
      isLoading: false,
      isBusy: false,
      historialActividad: [],

      // modal responder
      statusOptionsEjecutante: statusOptionsEjecutante,
      showModalRespuesta: false,
      isResolvingRespuesta: false,
      statusResponse: {
        selectedStatus: null,
        statusDetalle: null,
      },
      activityResponse: null

    }
  },
  computed: {

    loggedInUserId(){
      const user = getUserData()
      return user ? user.id : null
    },

    hasSomeNullEmptyItem(){
      const nullable = ( element ) => element === null || element === ''
      return Object.values( this.statusResponse ).some( nullable )

    },

    //
    hasErrors(){
      return this.errors !== null && this.errors !== undefined && this.errors.length !== 0
    },

    //
    isEmptyActivity(){
      return this.historialActividad.length === 0
    },

  },
  methods: {

    ...mapActions('tasks-module', ['fetchActividadFormularioTarea', 'createStatusResponse']),

    //cargar actividad del formulario
    async loadData(){

      try {
        this.isLoading = true
        this.historialActividad = await this.fetchActividadFormularioTarea( { frmId: this.frmId })
      } catch (error) {
        this.errors = error
        console.log("UNEXPECTED", error)
      } finally {
        this.isLoading = false
      }

    },

    //determinar si es estado final

    //
    isInProgress( status ){
      return !(status === COMPLETED_STATUS || status === CANCELED_STATUS)
    },


    // determinar formato de fecha hora
    getResolveFormatoFechaHora( date ){
      return resolveFormatoFechaHora( date )
    },
    // determinar color estado/etapa
    getFormStatusVariant( activity ){
      const status = {
        status: null,
        latestStatus: activity.fstStatus
      }
      return resolveFormStatusVariant(status)
    },
    // determinar nombre del estado
    getFormStatusName( activity ){
      const status = {
        status: null,
        latestStatus: activity.fstStatus
      }
      return resolveTaskStatusName( status )
    },

    // procesar peticion de responder
    handleResponse(activity){
      this.isResolvingRespuesta = true
      this.showModalRespuesta = true
      this.activityResponse = activity
    },

    // proceso para cerrar modal
    resetModal(){
      this.isResolvingRespuesta = false
    },

    // cancelar envío respuesta
    handleCancelModal() {
      this.showModalRespuesta = false
    },

    //procesar envio de respuesta
    async handleSendResponse(){

      try {

        this.isBusy = true

        const params = {
          frmId: this.activityResponse.frmId,
          fstUserId: this.loggedInUserId,
          fstStatus: this.statusResponse.selectedStatus.status,
          fstDetalle: this.statusResponse.statusDetalle
        }

        await this.createStatusResponse( params )

        await this.loadData()

        this.showModalRespuesta = false

      } catch (error) {
        console.log("ERROR Response", error)
      } finally {
        this.isBusy = false
      }

    }





  },
  async created() {
    await this.loadData()
  }
}
</script>


<style scoped lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
